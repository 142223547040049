<template>
  <div class="orderManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='orderManagement'">
        <div class="search_sList">
          <!-- <el-tabs v-model="activeName">
            <el-tab-pane label="订单管理" name="first"></el-tab-pane>
          </el-tabs> -->
          <el-tabs v-model="activeName" @tab-click="statusEvent">
            <el-tab-pane label="商用学校订单" name="commercial"></el-tab-pane>
            <el-tab-pane label="演示学校订单" name="demonstrate"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="100px">
              <el-form-item label="学生姓名">
                <el-input v-model="formInline.name" placeholder="学生姓名" clearable></el-input>
              </el-form-item>
              <el-form-item label="学校">
                <el-select v-model="formInline.tenantId" placeholder="请选择学校" @change="changeTen" filterable clearable>
                  <el-option
                    v-for="item in allSchoolData"
                    :key="item.tenantId"
                    :label="item.tenantName"
                    :value="item.tenantId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="批次">
                <el-select v-model="formInline.admissionBatch" filterable placeholder="选择批次">
                  <el-option
                    v-for="item in schoolBatchData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学号">
                <el-input v-model="formInline.studentNum" placeholder="学号" clearable></el-input>
              </el-form-item>
              <el-form-item label="省份">
                <el-select v-model="formInline.region" placeholder="请选择省份" filterable clearable>
                  <el-option
                    v-for="item in provinceData"
                    :key="item.provinceId"
                    :label="item.provinceName"
                    :value="item.provinceId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="教学点">
                <el-select v-model="formInline.stationId" placeholder="请选择教学点" filterable clearable>
                  <el-option
                    v-for="item in correspondenceStation"
                    :key="item.stationsId"
                    :label="item.stationsName"
                    :value="item.stationsId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="缴费方式">
                <el-select v-model="formInline.payType" placeholder="请选择缴费方式" clearable>
                  <el-option label="个人缴费" value="0"></el-option>
                  <el-option label="学校统交" value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="缴费状态">
                <el-select v-model="formInline.status" placeholder="请选择缴费状态" clearable>
                  <el-option v-for="(item, key) in $common
                  .statusData" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开票状态">
                <el-select
                  v-model="formInline.invoiceFlag"
                  placeholder="请选择开票状态"
                  clearable
                >
                  <el-option v-for="(item, key) in $common.invoiceStatusData" :label="item" :value="Number(key)" :key="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单创建时间">
                <el-date-picker
                  v-model="timeCreateVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="缴费时间">
                <el-date-picker
                  v-model="timeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="费用类型">
                <el-select
                  v-model="formInline.feeType"
                  placeholder="请选择费用类型"
                  clearable
                >
                  <el-option v-for="(item, key) in $common.payAuditData" :key="key" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <!-- <el-button class="mainBtn" @click="batchDelete" plain v-allow="'sys_order_del'">批量删除</el-button> -->
            <el-button class="mainBtn" @click="exportEvent" plain v-allow="'sys_order_export'">导出</el-button>
            <el-button class="mainBtn" @click="changePriceEvent" v-allow="'order_change_price'" plain>批量改价</el-button>
            <el-button class="mainBtn" @click="dialogDiyEvent('invoice')" v-allow="'sys_order_invoice'" plain>发票设置</el-button>
            <span class="dataTotal">
              <span>学生总费用：{{orderData.studentPayTotal || 0}}</span>
              <span>已支付费用：{{orderData.payFees || 0}}</span>
              <span>未支付费用：{{orderData.unPayFees || 0}}</span>
            </span>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
            row-key='id'
            ref="multipleTable"
            @selection-change="handleSelectionChange">
            <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="55"
              fixed="left">
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              align="center"
              width="120">
            </el-table-column>
            <el-table-column
              prop="province"
              label="省份"
              align="center"
              width="100">
            </el-table-column>
            <el-table-column
              prop="schoolName"
              label="学校"
              align="center"
              width="120">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.schoolName" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.schoolName || '/'}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="studentNum"
              label="学号"
              align="center"
              width="140">
            </el-table-column>
            <el-table-column
              prop="admissionBatch"
              label="批次"
              align="center"
              width="100">
            </el-table-column>
            <el-table-column
              prop="stationName"
              label="教学点"
              align="center"
              width="100">
            </el-table-column>
            <el-table-column
              prop="feeType"
              label="费用类型"
              align="center"
              width="100">
              <template slot-scope="scope">
                <span>{{ $common.payAuditData[scope.row.feeType] || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="unitPrice"
              label="收费单价"
              align="center">
            </el-table-column>
            <el-table-column
              prop="actualUnitPrice"
              label="实收单价"
              align="center">
            </el-table-column>
            <el-table-column
              label="开票状态"
              align="center">
              <template slot-scope="scope">
                <span>{{ $common.invoiceStatusData[scope.row.invoiceFlag] || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="payType"
              label="缴费方式"
              align="center">
              <template slot-scope="scope">
                <span>{{ $common.payTypeData[scope.row.payType] || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="spType"
              label="支付平台"
              align="center">
              <template slot-scope="scope">
                <span>{{ $common.spTypeData[scope.row.spType] || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="缴费状态"
              align="center">
              <template slot-scope="scope">
                <span>{{ $common.statusData[scope.row.status] }}</span>
                <!-- <span :class="[scope.row.status == 1 ? 'payed' : 'not_pay']">{{scope.row.status == 1 ? '已支付' : '未支付'}}</span> -->
              </template>
            </el-table-column>
            <el-table-column
              prop="learningPermissions"
              label="学习权限"
              align="center">
              <template slot-scope="scope">
                <span>{{ $common.learningPermissionsData[scope.row.learningPermissions] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="payDate"
              label="缴费时间"
              align="center"
              width="180">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.payDate, 'datetime') || '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="订单创建时间"
              align="center"
              width="180">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime, 'datetime') || '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="140"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" slot="reference" @click="watchState(scope.row)">查看详情</el-button>
                <!-- <el-button @click="deleteEvent(scope.row)" type="text" v-allow="'sys_order_del'">删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
    <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="">
        <p>{{deleteType == 1 ? '确定要删除该订单吗？' : '确定要批量删除订单吗？'}}</p>
        <div class="btn_area">
          <el-button @click="batchDelete">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
    <!-- 发票设置 -->
    <dialogDiyNew :isShow="isShowInvoice" :dialogDiyData="InvoiceData" @dialogEvent="dialogDiyEvent('invoice')">
      <div slot="dialogContent" class="dia_opera">
        <div class="mainArea">
          <el-form :inline="false" :model="formInvoice" class="demo-form-inline" label-width="120px">
              <el-form-item label="税号">
                <el-input v-model="formInvoice.taxNo" placeholder="请输入税号" clearable></el-input>
              </el-form-item>
              <el-form-item label="开具方式">
                <el-radio-group v-model="formInvoice.taxType" @change="changeTaxType(formInvoice.taxType)">
                  <el-radio :label="0">全电</el-radio>
                  <el-radio :label="1">税控</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="设备号" v-if='formInvoice.taxType===1'>
                <el-input v-model="formInvoice.taxDiskNo" placeholder="请输入设备号" clearable></el-input>
              </el-form-item>
              <el-form-item label="开票人">
                <el-input v-model="formInvoice.drawer" placeholder="请输入开票人" clearable></el-input>
              </el-form-item>
              <el-form-item label="复核人">
                <el-input v-model="formInvoice.checker" placeholder="请输入复核人" clearable></el-input>
              </el-form-item>
              <el-form-item label="收款人">
                <el-input v-model="formInvoice.payee" placeholder="请输入收款人" clearable></el-input>
              </el-form-item>
              <el-form-item label="平台费商品编号">
                <el-input v-model="formInvoice.tuitionGoodsCode" placeholder="请输入平台费商品编号" clearable></el-input>
              </el-form-item>
              <el-form-item label="平台费商品名称">
                <el-input v-model="formInvoice.tuitionGoodsName" placeholder="请输入平台费商品名称" clearable></el-input>
              </el-form-item>
              <el-form-item label="平台费税率">
                <el-select
                  v-model="formInvoice.tuitionGoodsTaxRate"
                  placeholder="请选择平台费税率"
                  clearable
                >
                  <el-option v-for="(item, key) in taxRates" :label="item" :value="Number(key)" :key="key"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="联系人">
                <el-input class="phoneInput" v-model="formInvoice.drawerPhone" placeholder="请输入联系人" clearable></el-input>
                <span>（用于接收票务相关短信）</span>
              </el-form-item>
            </el-form>
        </div>
        <div class="btn_area">
          <el-button @click="dialogDiyEvent('invoice')">关闭</el-button>
          <el-button class="mainBtn" @click="postInvoiceSet">确定</el-button>
        </div>
      </div>
    </dialogDiyNew>

    <!-- 导出 -->
    <fileExportNew
      :isShowFile="isShowFile"
      :title="title"
      @closeFile="exportEvent"
      @enter="enterExportEvent"
    ></fileExportNew>

    <!-- 批量改价 -->
    <fileExportNew
      :isShowFile="changePriceObj.isShowFile"
      :title="changePriceObj.title"
      :txtObj="changePriceObj.txtObj"
      :dialogData="changePriceObj.dialogFEData"
      @closeFile="changePriceEvent"
      @enter="enterChangePriceEvent"
    >
      <template #exporttips>
        <div class="form-price-area">
          <div>收费单价</div>
          <div>
            <el-input
              v-model="unitPrice"
              placeholder="收费单价"
              clearable
            ></el-input>元
          </div>
        </div>
      </template>
    </fileExportNew>

    <dialogSubmit :isShowSub="isShowSub" @closeSub="closeSub"></dialogSubmit>
    <input style="display: none;" type="file" @change="picChange()" ref="loadPic" accept="image/*,video/*" />
  </div>
</template>

<script>
import publicChildren from '../publicChildren'
import Pagination from '../Pagination'
import dialogDiy from '../commonModule/dialogDiy'
import dialogDiyNew from '../commonModule/dialogDiyNew'
import dialogSubmit from '../commonModule/dialogSubmit'
import fileExportNew from '../commonModule/fileExportNew'
import way from '../../api/encapsulation'
export default {
  name: 'orderManagement',
  components: {
    publicChildren,
    Pagination,
    dialogSubmit,
    fileExportNew,
    dialogDiyNew,
    dialogDiy
  },
  data() {
    return {
      activeName: 'commercial',
      orderData: {},
      formInline: {
        nature: 1,
        feeType: null,
        invoiceFlag: null,
        admissionBatch: null,
        name: null,
        payTimeEnd: null,
        payTimeStart: null,
        createStartTime: null,
        createEndTime: null,
        payType: null,
        region: null,
        stationId: null,
        status: null,
        studentNum: null,
        tenantId: null,
        pageSize: 10,
        currentPage: 1
      },
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      formInvoice:{},
      isShowInvoice: false,
      InvoiceData: {
        title: '发票设置',
        sizeStyle: {width: '600px',height: 'auto'}
      },
      taxRates:{
        0.01:'1%',
        0.03:'3%',
        0.06:'6%',
        0.09:'9%',
        0.11:'11%',
        0.13:'13%',
        0.17:'17%',
      },
      isShow: false,
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
      deleteType: 1, // 1 单个删除， 2 批量删除
      timeVal: '',
      timeCreateVal: '',
      rowStatus: '',
      idArr: [],
      provinceData: [],
      allSchoolData: [],
      schoolBatchData: [],
      correspondenceStation: [],
      isShowSub: false,
      isShowFile: false,
      title: "请选择导出学生订单",
      changePriceObj: {
        isShowFile: false,
        title: "请选择改价学生范围",
        txtObj: ['全部筛选信息', '已勾选信息'],
        dialogFEData: {
          width: '400px',
          height: '260px'
        }
      },
      unitPrice: null,
      selectArr: []
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'orderManagement') {
          this.getMon();
          this.getGoSchoolData();
          this.getTheDropDownBoxData();
          this.getOrderDataStatistics();
        }
      }
    }
  },
  created() {
    this.getProviceEvent();
    this.getTenantDown();
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    
    handleKeydown(event) {
      if (!this.isShow&&!this.isShowFile&&!this.changePriceObj.isShowFile&&!this.isShowSub) {
        this.onSubmit()
      }
    },
    statusEvent(tab) {
      this.formInline.nature = tab.paneName == 'commercial' ? 1 : 2
      this.formInline.currentPage = 1;
      this.formInline.pageSize = 10;
      this.getOrderDataStatistics();
      this.getOrderTableData();
    },
    // 获取今年一月一日到现在的时间段
    getMon() {
      this.timeCreateVal = this.$way.nowYearMon()
      this.getOrderTableData()
    },
    // 获取发票设置
    getInvoiceSet() {
      this.loading = true;
      let obj = {};
      this.$request.getInvoiceSet().then(res=>{
        this.loading = false;
        if(res.data.code == 0){
          this.$set(this,'formInvoice',res.data.data)
        }
      })
    },
    changeTaxType(type) {
      if (type===0) {
        console.log(type,'type');
      }
    },
    // 发票设置提交
    postInvoiceSet() {
      this.loading = true;
      let obj = this.formInvoice;
      console.log(obj);
      if (obj.taxType===0) {
        delete obj.taxDiskNo
      }
      this.$request.postInvoiceSet(obj).then(res=>{
        this.loading = false;
        if(res.data.code == 0){
          this.isShowInvoice=false
        }
      })
    },
    // 获取订单列表表格数据
    getOrderTableData() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getOrderData(obj, res => {
        this.loading = false;
        if(res.code == 0){
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getProvice({}, res => {
        if(res.code == 0) {
          this.provinceData = res.data;
        }
      })
    },
    hasEvent() {
      if(!this.formInline.tenantId) {
        this.Warn('请先选学校');
        return;
      }
    },
    // 获取入学批次数据
    getGoSchoolData() {
      let obj = {
        tenantId: 0
      }
      this.$request.getGoSchool(obj, res => {
        if(res.code == 0) {
          this.schoolBatchData = res.data;
        }
      })
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if(res.code == 0) {
          this.allSchoolData = res.data;
        }
      })
    },
    // 获取教学点数据
    getTheDropDownBoxData() {
      let obj = {
        tenantId: this.formInline.tenantId
      }
      this.$request.getTheDropDownBoxs(obj, res => {
        if(res.code == 0){
          this.correspondenceStation = res.data;
        }
      })
    },
    changeTen() {
      this.formInline.stationId = null
      // this.formInline.admissionBatch = null
      // this.schoolBatchData = []
      this.getTheDropDownBoxData()
    },
    regPayType(row) {
      let edu = String(row.payType)
      let val = '';
      switch(edu) {
        case '0':
          val = '个人缴费';
          break;
        case '1':
          val = '学校统交';
          break;
        default:
          val = '';
      }
      return val;
    },
    regStatus(row) {
      let edu = String(row.status)
      let val = '';
      switch(edu) {
        case '0':
          val = '未支付';
          break;
        case '1':
          val = '已支付';
          break;
        default:
          val = '';
      }
      return val;
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.timeVal = '';
      this.getMon()
    },
    handleSelectionChange(val) {
      this.idArr = val.map(item => item.id)
      this.selectArr = val
    },
    // 导出
    exportEvent() {
      this.isShowFile = !this.isShowFile;
    },
    enterExportEvent(val) {
      if (val == 1 && this.idArr.length == 0) {
        this.Warn("请选择导出学生订单数据");
        return;
      }
      let obj = JSON.parse(JSON.stringify(this.formInline))
      obj.ids = val == 1 ? this.idArr : []
      console.log(obj);
      // return;
      this.$request.exportOrderData(obj).then(res=> {
        if(res.data.code == 0) {
          this.Success(res.data.data)
          this.exportEvent()
          this.isShowSub = true
          this.idArr = []
          this.$refs.multipleTable.clearSelection()
        }
      })
    },
    // 批量删除
    batchDelete() {
      this.deleteType = 2;
      this.isShow = !this.isShow;
    },
    dialogDiyEvent(val) {
      if (val=='invoice') {
        this.isShowInvoice = !this.isShowInvoice
        if (this.isShowInvoice) {
          this.getInvoiceSet()
        }
      } else {
        this.isShow = val.isShow;
      }
    },
    // 查看详情
    watchState(row) {
      this.$router.push({name:'orderDetails',query: {
        id: row.id
      }})
    },
    // 删除
    deleteEvent(row) {
      this.deleteType = 1;
      this.rowStatus = row;
      this.isShow = !this.isShow;
    },
    doEvent() {
      if(this.deleteType == 1) {
        this.onlyDelete();
      } else {
        this.moreDelete();
      }
    },
    // 单个删除执行事件
    onlyDelete() {
      let id = this.rowStatus.id;
      this.$request.deleteOrderById({id}, res => {
        if(res.code == 0) {
          this.isShow = !this.isShow;
          this.rowStatus = '';
          this.Success(res.msg);
          this.formInline.currentPage = 1;
          this.getOrderTableData();
        }
      })
    },
    // 批量删除执行事件
    moreDelete() {
      if(this.idArr.length == 0) {
        this.Warn('未选择需批量删除的数据');
        return;
      }
      let ids = this.idArr;
      this.$request.batchDeleteOrder({ids}).then( res => {
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.currentPage = 1;
          this.getOrderTableData();
        }
      })
    },
    onSubmit() {
      if(this.timeVal) {
        this.formInline.payTimeStart = way.dateReturnTimestamp(this.timeVal[0]);
        this.formInline.payTimeEnd = way.dateReturnTimestamp(this.timeVal[1]);
      }
      if(this.timeCreateVal) {
        this.formInline.createStartTime = way.dateReturnTimestamp(this.timeCreateVal[0]);
        this.formInline.createEndTime = way.dateReturnTimestamp(this.timeCreateVal[1]);
      }
      this.formInline.currentPage = 1;
      this.formInline.pageSize = 10;
      this.getOrderDataStatistics();
      this.getOrderTableData();
    },
    // 确认上传
    getLocalPic() {
      this.$refs.loadPic.value= '';
      this.$refs.loadPic.click();
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.currentPage = val.current;
      this.formInline.pageSize = val.size;
      this.getOrderTableData();
    },
    closeSub(val) {
      this.isShowSub = val.isShow;
    },
    // 数据统计
    getOrderDataStatistics() {
      this.$request.orderDataStatistics(this.formInline).then(res => {
        if(res.data.code == 0) {
          this.orderData = res.data.data || {}
        }
      })
    },
    changePriceEvent() {
      this.$set(this.changePriceObj, 'isShowFile', !this.changePriceObj.isShowFile)
      if (!this.changePriceObj.isShowFile) {
        this.unitPrice = null
      }
    },
    enterChangePriceEvent(val) {
      if (val == 1) {
        if (this.idArr.length == 0) {
          this.Warn('请勾选要改价的数据')
          return;
        }
        let fArr = this.selectArr.filter(i => i.status != '0')
        if (fArr.length != 0) {
          this.Warn('勾选数据存在不符合要求的数据')
          return
        }
      }
      if (this.unitPrice == '' || this.unitPrice == null) {
        this.Warn('请输入收费单价')
        return;
      }
      let obj = {
        qo: {
          ...this.formInline,
          ids: val == 1 ? this.idArr : []
        },
        unitPrice: this.unitPrice
      }
      this.$request.platformOrderChangePrice(obj).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg || '操作成功')
          this.$refs.multipleTable.clearSelection()
          this.changePriceEvent()
          this.getOrderDataStatistics();
          this.getOrderTableData();
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .phoneInput {
  width: 200px;
}
</style>
<style lang="less">
  .orderManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .dataTotal {
      span {
        font-size: 14px;
        color: #333;
        margin: 0 10px;
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .pop_del {
        margin-left: 6px;
      }
      .el-pagination {
        text-align: right;
      }
      .payed {
        color: #409EFF;
      }
      .not_pay {
        color: #FF8A0C;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .pubToop {
    max-width: 600px;
  }
</style>

<style lang="less" scoped>
  .form-price-area {
    margin-top: 20px;
    display: flex;
    align-items: center;
    & > div:nth-of-type(2) {
      display: flex;
      align-items: center;
      /deep/ .el-input {
        margin: 0 20px;
      }
    }
  }
</style>